import React from 'react';

import { t } from '@/utility/localization';
import { getCountdownFormattedString } from '@/utility/stringHelper';
import withComponentClassName from '@/utility/withComponentClassName';

import Icon from '@/components/npl/Icon';

import useCountdown from '@/hooks/useCountdown';

type UpsellDiscountDisplayProps = {
  effectiveTimeEnd: string;
  value: number;
};

const UpsellDiscountDisplay: React.FC<UpsellDiscountDisplayProps> = ({
  value,
  effectiveTimeEnd
}) => {
  const { countdown, hasEnded } = useCountdown(effectiveTimeEnd);

  const isValidDiscountTime =
    effectiveTimeEnd &&
    new Date(effectiveTimeEnd) > new Date() &&
    !hasEnded;

  return (
    <div className="z-[-1] w-full animate-moveDownLibrary rounded-b-8 border-b-1 border-l-1 border-r-1 border-npl-transparent-black-10 bg-npl-orange-light-3 p-8  transition-all">
      <div className="flex justify-center gap-4 text-label-sm text-npl-orange-light-11">
        <Icon
          name="tag-01"
          width={16}
          height={16}
          className="fill-npl-orange-light-11"
        />
        <span>
          {[
            <span className="font-medium" key="percentage">
              {t('perc_off', {
                perc: value || 0
              })}
            </span>,
            isValidDiscountTime
              ? [
                  '',
                  '·',
                  t('offer-ends-in'),
                  getCountdownFormattedString(countdown)
                ].join(' ')
              : ''
          ]}
        </span>
      </div>
    </div>
  );
};

export default withComponentClassName(UpsellDiscountDisplay);
