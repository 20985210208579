import { t } from '@/utility/localization';

import PortalBanner from '../PortalBanner/Portalbanner';

const ExpiredSoonBanner = ({ onClick, onClose }) => {
  return (
    <PortalBanner
      onClick={onClick}
      onClose={onClose}
      showButtonIcon={true}
      showAlertIcon={true}
      ctaText={t('renew-membership-0')}
      text={t('your-membership-is-expiring-soon-please-renew-it')}
    />
  );
};

export default ExpiredSoonBanner;
