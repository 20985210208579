import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { checkIsCommunityCheckoutRequired } from '@/utility/communityHelpers';
import { checkIfIndexable, checkIfWebIsInFrame } from '@/utility/helpers';
import localStorageService from '@/utility/localStorageService';
import { t } from '@/utility/localization';
// import SignUpPopup from '@/features/SignUpPopup';
import {
  getCommunityCheckoutRoute,
  getMemberCommunityPageRoute
} from '@/utility/routesHelper';

import CompleteYourProfileModalWrapper from '@/features/User/CompleteYourProfileModal/CompleteYourProfileModalWrapper';
import {
  getIsSignupPopupInitiallyVisible,
  getLowestIntervalPricing
} from '@/features/community/utils';

import { useAuthContext } from '@/contexts/AuthContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import AnimatedShowHideDiv from '@/components/common/AnimatedShowHideDiv/AnimatedShowHideDiv';
import NextImage from '@/components/common/NextImage';
import PageMetaTags from '@/components/common/PageMetaTags';
import PublicPageFooter from '@/components/common/PublicPageFooter';
import { showSuccessToast } from '@/components/common/ToastContainer';

import useCommonPublicPageData from '@/hooks/useCommonPublicPageData';
import useQueryParams from '@/hooks/useQueryParams';

import ExpiredSoonBanner from '@/pages/portal/components/ExpiredSoonBanner/ExpiredSoonBanner';
import { COMMUNITY_RENEWAL_AVAILABLE } from '@/pages/portal/constants';
import { getExpiredStatus } from '@/pages/user/utils/communityUtils';

import GenericNavBar from '../../Navbar/GenericNavbar';
import Tabs from '../components/common/Tabs';
import CommunityDetailsSection from '../components/home/CommunityDetailsSection';
import WithPendingCommMembershipApprovalModal from '../components/home/WithPendingCommMembershipApprovalModal';
import { TABS_ID } from '../constants';
import useCommunityLandingPageData from '../hooks/useCommunityLandingPageData';

const WithWelcomeToCommunitySplashScreen = dynamic(
  () => import('../components/home/WithWelcomeToCommunitySplashScreen'),
  {
    ssr: false
  }
);
const CommunitySignupModal = dynamic(
  () => import('@/features/CommunitySignupModal/CommunitySignupModal'),
  {
    ssr: false
  }
);

const CommunityPageLayout = ({
  children,
  communityPublicPageData, // OPTIONAL. getting from get static props.
  userCommunitiesData,
  showBanner,
  setHasScrollPastBanner = () => {},
  hasScrolledPastBanner = false
}) => {
  const { isGtEqMd } = useWindowWidthContext();
  // const [isAnnouncementBannerOpened, setIsAnnouncementBannerOpened] =
  //   useState(false);
  const [closedExpiredSoonBanner, setClosedExpiredSoonBanner] =
    useState(false);
  const [
    showWelcomeToCommunitySplashScreen,
    setShowWelcomeToCommunitySplashScreen
  ] = useState(false);
  const { isLoggedIn, hasToken } = useAuthContext();
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const communityId = communityPublicPageData?._id;
  const communityCode = communityPublicPageData?.code;
  const communityConfig = communityPublicPageData?.additionalConfig;

  const isCommunityCheckoutRequired =
    communityPublicPageData?.isPaidCommunity ||
    checkIsCommunityCheckoutRequired(communityPublicPageData);

  const communityDetailsRef = useRef(null);
  const communitySlug = communityPublicPageData?.slug;
  const {
    communityLandingPageData: communityPublicPageDataLatest,
    mutate: mutateCommunityData
  } = useCommunityLandingPageData({
    communitySlug,
    initialValue: communityPublicPageData
  });

  const {
    isCommunityAdmin,
    isPendingApproval,
    isCommunityMember,
    // user communities data
    isUserCommunitiesFetched,
    userCommunities,
    setIsSignUpModalOpened,
    isSignUpModalOpened,
    openSignUpModal,
    onSignUpPopupClose,
    mutateUserCommunities,
    isSignupModalToBecomeAffiliate,
    joinAsAffiliateProps
  } = userCommunitiesData;

  const { onOpenJoinAsAffiliateModal } = joinAsAffiliateProps;

  const commonPublicPageData = useCommonPublicPageData({
    communityInfo: communityPublicPageData
  });

  const {
    pricingData,
    priceTagText,
    discountCodeFromQueryParams,
    discountedCodeData
  } = commonPublicPageData;

  const isIndexable = checkIfIndexable(communityPublicPageDataLatest);

  const showHideTabsData = {
    [TABS_ID.EVENTS]: communityPublicPageData?.hasEvents,
    [TABS_ID.PRODUCTS]: communityPublicPageData?.hasProducts,
    [TABS_ID.CHALLENGES]: communityPublicPageData?.hasChallenges,
    [TABS_ID.CHAT]: communityPublicPageData?.hasChat,
    [TABS_ID.ABOUT]: !showBanner && isCommunityMember
  };

  const goToCommunityCheckout = (params = {}) => {
    const { isForAffiliateSignup } = params;

    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityPublicPageData?._id,
      communityCode: communityPublicPageData?.code,
      discountCode: discountCodeFromQueryParams,
      affiliateCode: currentRouterQuery.affiliateCode,
      withJoinAsAffiliateCb: Boolean(isForAffiliateSignup)
    });

    router.push(checkoutRoute);
  };

  const openSignUpModalMiddleware = (params = {}) => {
    const { isForAffiliateSignup } = params;

    // For paid community or appln gated, go to checkout
    if (isCommunityCheckoutRequired) {
      goToCommunityCheckout({
        isForAffiliateSignup: Boolean(isForAffiliateSignup)
      });
      return;
    }

    // else open signup modal i.e for FREE Signup
    mutateCommunityData();

    openSignUpModal({
      onlySignupToCommunity: true,
      isSignupModalToBecomeAffiliate: Boolean(isForAffiliateSignup)
    });
  };

  const activeCommunity = userCommunities.find(
    (community) => community?._id === communityPublicPageData?._id
  );

  const communityExpiredStatus = useMemo(
    () => getExpiredStatus(activeCommunity?.subscriptions[0]),
    [activeCommunity]
  );

  // stripe india communities only
  const showExpiredSoonBanner = useMemo(() => {
    return (
      !localStorageService.getItem(
        `hideExpiredSoonBanner-${activeCommunity?._id}`
      ) &&
      !closedExpiredSoonBanner &&
      communityExpiredStatus === COMMUNITY_RENEWAL_AVAILABLE
    );
  }, [activeCommunity, communityExpiredStatus, closedExpiredSoonBanner]);

  function closeExpiredSoonBanner() {
    localStorageService.setItem(
      `hideExpiredSoonBanner-${activeCommunity?._id}`,
      true
    );
    setClosedExpiredSoonBanner(true);
  }

  const genericTrackingFields = {
    communityId,
    communityCode
  };

  // We need to look through all payment plans and find one with highest saving
  const discountPercentageForHighestInterval = useMemo(() => {
    // If only one plan return empty text
    if (!pricingData || pricingData?.length === 1) return null;

    // Get default pricing
    const lowestIntervalPricing = getLowestIntervalPricing(pricingData);
    // can't assume that the lowest interval pricing is a monthly option
    const monthlyPriceOfLowestIntervalPricing =
      lowestIntervalPricing?.unit_amount /
      lowestIntervalPricing?.recurring?.interval_count;

    let biggestSaving = { discount: 0 };

    // Loop through all plans and find biggest saving.
    for (const pricing of pricingData) {
      const interval =
        pricing?.recurring?.interval === 'year'
          ? 12
          : pricing?.recurring?.interval_count;

      // get relative monthlyPrice for this plan
      const monthlyPrice = pricing?.unit_amount / interval;

      // calculate discount by comparing to 1-month plan
      const calculatedDiscount = Math.trunc(
        ((monthlyPriceOfLowestIntervalPricing - monthlyPrice) /
          monthlyPriceOfLowestIntervalPricing) *
          100
      );

      if (calculatedDiscount > biggestSaving?.discount) {
        biggestSaving.discount = calculatedDiscount;
        biggestSaving.interval = interval;
      }
    }

    // Formulate a text to display
    return biggestSaving.discount > 0
      ? biggestSaving.interval === 12
        ? {
            text: t('or-save-math-trunc-biggestsaving-discount-annually', {
              percent: Math.trunc(biggestSaving.discount)
            }),
            discounted: true
          }
        : {
            text: t(
              'or-save-math-trunc-biggestsaving-discount-per-biggestsaving-interval-months',
              {
                percent: Math.trunc(biggestSaving.discount),
                interval: biggestSaving.interval
              }
            ),
            discounted: true
          }
      : { text: t('more-plans-available-on-checkout'), discounted: false };
  }, [pricingData]);

  const joinCommunityButtonCTAProps = {
    onClick: openSignUpModalMiddleware,
    hierarchy: 'accent_primary',
    disabled: isPendingApproval,
    buttonText: isPendingApproval
      ? t('pending-approval')
      : t('join-community')
  };

  const handlePostMemberLogin = () => {
    router.push({
      pathname: getMemberCommunityPageRoute(communityPublicPageData.slug),
      query: {
        showSignupSuccessPrompt: 1
      }
    });

    mutateUserCommunities();

    // Mutuate community data to update any info that would have been hidden for non members. Eg chat link.
    mutateCommunityData();

    if (isSignupModalToBecomeAffiliate) {
      onOpenJoinAsAffiliateModal();
    }
  };

  const handlePostFreeCommunitySignup = (isPending) => {
    if (!isPending) {
      // This is so that the splashSreen is shown instantly
      // instead of waiting for the page to load
      setShowWelcomeToCommunitySplashScreen(true);
      showSuccessToast(
        t('success-you-are-a-member-of-community', {
          communityName: communityPublicPageData.title
        })
      );
      router.push({
        pathname: getMemberCommunityPageRoute(
          communityPublicPageData.slug
        ),
        query: {
          showSignupSuccessPrompt: 1
        }
      });
    }
    mutateUserCommunities();
    mutateCommunityData();
    if (isSignupModalToBecomeAffiliate) {
      onOpenJoinAsAffiliateModal();
    }
  };

  const isInFrame = checkIfWebIsInFrame();

  //For caching issue, should only setIsSignUpModal initially opened or not on page load with js loaded
  useEffect(() => {
    if (!isUserCommunitiesFetched) return;
    if (hasToken) return; // if user is logged in, then quick signup modal is not required.

    // if pendingApproval = true, then popUp won't show at all
    // if pendingApproval = false, then will check if SignupPopUp is initially visible
    const isSignupOverlayInitiallyVisible =
      getIsSignupPopupInitiallyVisible({
        communityId,
        communityConfig,
        isPendingApproval
      });
    // only setIsSignUpModalOpened when userCommunities are fetched
    setIsSignUpModalOpened(isSignupOverlayInitiallyVisible && !isInFrame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserCommunitiesFetched, hasToken]);

  return (
    <div className="transition-all c-CommunityPageLayout">
      <PageMetaTags
        {...communityPublicPageDataLatest?.metadata}
        indexable={isIndexable}
      />
      {!isInFrame && (
        <>
          <div className="c-BlurredBackground fixed left-0 top-0 z-[-2] h-full w-full ">
            <div className="absolute left-0 top-0 z-1 h-full w-full bg-white-default opacity-[0.96] " />
            <div className="absolute left-0 top-0 z-1 h-full w-full bg-black opacity-[0.03] " />

            <NextImage
              {...{
                className: `w-full h-full blur-3xl`,
                mobileImgProps: {
                  src: communityPublicPageDataLatest?.backgroundImage,
                  quality: 1,
                  layout: 'fill',
                  height: '100%',
                  objectFit: 'cover'
                }
              }}
            />
          </div>
          {isUserCommunitiesFetched && !isLoggedIn && (
            <GenericNavBar
              communityInfo={communityPublicPageDataLatest}
              hasScrolledPastBanner={hasScrolledPastBanner}
              openSignUpModal={openSignUpModal}
              isCommunityPageView={true}
              showBanner={showBanner}
            />
          )}
          {showExpiredSoonBanner && (
            <ExpiredSoonBanner
              onClick={() =>
                router.push(
                  getCommunityCheckoutRoute({
                    communityCode: activeCommunity?.code,
                    communityId: activeCommunity?._id,
                    renewal: true,
                    affiliateCode: currentRouterQuery?.affiliateCode
                  })
                )
              }
              onClose={() => closeExpiredSoonBanner({ activeCommunity })}
            />
          )}
        </>
      )}
      <div ref={communityDetailsRef}>
        <AnimatedShowHideDiv show={showBanner} duration={250}>
          <CommunityDetailsSection
            communityPublicPageData={communityPublicPageDataLatest}
            openSignUpModal={openSignUpModalMiddleware}
            priceTagText={priceTagText}
            isPendingApproval={isPendingApproval}
            discountPercentageForHighestInterval={
              discountPercentageForHighestInterval
            }
            discountCodeFromQueryParams={discountCodeFromQueryParams}
            joinCommunityButtonCTAProps={joinCommunityButtonCTAProps}
            discountedCodeData={discountedCodeData}
          />
        </AnimatedShowHideDiv>
      </div>
      {!isInFrame && (
        <>
          <div
            className={classNames(
              'hide-scrollbar sticky z-[100] mx-0 overflow-x-scroll rounded-t-12 border-b-1 border-npl-transparent-black-5 bg-white-default/75 backdrop-blur-xl backdrop-opacity-100 transition-all',
              {
                'fixed !top-64': showBanner,
                // 'top-0 ': !showBanner,
                'top-64 ': !showBanner,
                'mx-12': !hasScrolledPastBanner && isGtEqMd,
                'mt-12': showExpiredSoonBanner
              }
            )}>
            <Tabs
              communityData={communityPublicPageDataLatest}
              setHasScrollPastBanner={setHasScrollPastBanner}
              isCommunityAdmin={isCommunityAdmin}
              isCommunityMember={isCommunityMember}
              showBanner={showBanner}
              showHideTabsData={showHideTabsData}
              genericTrackingFields={genericTrackingFields}
              communityDetailsRef={communityDetailsRef}
            />
          </div>
        </>
      )}
      {/* <div
        className={classNames({
          'h-[calc(100vh-112px] hide-scrollbar fixed bottom-0 top-[112px] flex w-[100vw] flex-col overflow-y-auto overflow-x-visible':
            !showBanner,
          'animate-fadeInCommLayout': !showBanner,
          '!top-[176px]': showExpiredSoonBanner
        })}> */}
      <div>
        <div
          className={classNames(
            'rounded-b-12 shadow-npl-styles-shadow-01',
            {
              'bg-white-default/50 md:mx-12': !isInFrame
            }
          )}>
          <div className="mx-auto min-h-[600px] max-w-[688px]">
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                communityPublicPageDataLatest,
                commonPublicPageData: commonPublicPageData,
                userCommunitiesData: userCommunitiesData,
                discountPercentageForHighestInterval,
                hasScrolledPastBanner,
                genericTrackingFields,
                joinCommunityButtonCTAProps,
                mutateCommunityData,
                activeCommunity
              });
            })}
          </div>
        </div>
        {!isInFrame && <PublicPageFooter communitySlug={communitySlug} />}
      </div>
      {/* Modals */}
      {/* Signup flow */}
      <CommunitySignupModal
        open={isSignUpModalOpened}
        onClose={onSignUpPopupClose}
        communityInfo={{
          ...communityPublicPageData,
          pricingData
        }}
        priceTagText={priceTagText}
        discountCodeFromQueryParams={discountCodeFromQueryParams}
        newMemberSignupCallback={handlePostFreeCommunitySignup}
        existingMemberLoginCallback={handlePostMemberLogin}
        isSignupModalToBecomeAffiliate={isSignupModalToBecomeAffiliate}
      />
      <WithWelcomeToCommunitySplashScreen
        communityInfo={communityPublicPageData}
        open={showWelcomeToCommunitySplashScreen}
      />
      <WithPendingCommMembershipApprovalModal />
      {!currentRouterQuery?.showSignupSuccessPrompt &&
        !currentRouterQuery?.showMembershipPendingApproval &&
        isPendingApproval && <CompleteYourProfileModalWrapper />}
      {/* Note: Only need to check for isPendingApproval case here. isCommunityMember case is handled in /home.page.js */}
    </div>
  );
};

export default CommunityPageLayout;
