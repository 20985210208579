import { useCallback, useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import {
  checkDiscountCode,
  createDiscountCode,
  editDiscountCode,
  getDiscountInfo,
  getDiscounts
} from '@/services/discountService';

import { NPL_PILL_TYPES } from '@/components/common/NPLPill/constants';
import { showErrorToast } from '@/components/common/ToastContainer';

export const DISCOUNT_ENTITY_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  EVENT: 'EVENT',
  FOLDER: 'FOLDER',
  SESSION: 'SESSION',
  CHALLENGE: 'CHALLENGE',
  ALL: 'ALL'
};

export const PROMOTION_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  FULLY_REDEEMED: 'FULLY_REDEEMED'
};

export const getDisplayedPromotionStatuses = () => ({
  [PROMOTION_STATUSES.ACTIVE]: {
    label: t('active'),
    pillType: NPL_PILL_TYPES.SUCCESS
  },
  [PROMOTION_STATUSES.INACTIVE]: {
    label: t('inactive-0'),
    pillType: NPL_PILL_TYPES.NEUTRAL
  },
  [PROMOTION_STATUSES.EXPIRED]: {
    label: t('expired'),
    pillType: NPL_PILL_TYPES.ERROR
  },
  [PROMOTION_STATUSES.FULLY_REDEEMED]: {
    label: t('fully-redeemed'),
    pillType: NPL_PILL_TYPES.ORANGE
  }
});

const getPromotionStatusFilterOptions = (onOptionClick) => {
  const displayedPromotionStatuses = getDisplayedPromotionStatuses();
  return [
    {
      label: t('all'),
      value: null,
      onClick: () => onOptionClick(null)
    },
    {
      isDivider: true
    },
    {
      label: displayedPromotionStatuses[PROMOTION_STATUSES.ACTIVE].label,
      value: PROMOTION_STATUSES.ACTIVE,
      onClick: () => onOptionClick(PROMOTION_STATUSES.ACTIVE)
    },
    {
      label: displayedPromotionStatuses[PROMOTION_STATUSES.INACTIVE].label,
      value: PROMOTION_STATUSES.INACTIVE,
      onClick: () => onOptionClick(PROMOTION_STATUSES.INACTIVE)
    },
    {
      label:
        displayedPromotionStatuses[PROMOTION_STATUSES.FULLY_REDEEMED]
          .label,
      value: PROMOTION_STATUSES.FULLY_REDEEMED,
      onClick: () => onOptionClick(PROMOTION_STATUSES.FULLY_REDEEMED)
    },
    {
      label: displayedPromotionStatuses[PROMOTION_STATUSES.EXPIRED].label,
      value: PROMOTION_STATUSES.EXPIRED,
      onClick: () => onOptionClick(PROMOTION_STATUSES.EXPIRED)
    }
  ];
};

export const getDiscountEntityTypeFromEntityType = (entityType) => {
  switch (entityType) {
    case 'event':
      return DISCOUNT_ENTITY_TYPE.EVENT;
    case 'folder':
      return DISCOUNT_ENTITY_TYPE.FOLDER;
    case 'session':
      return DISCOUNT_ENTITY_TYPE.SESSION;
    case 'challenge':
      return DISCOUNT_ENTITY_TYPE.CHALLENGE;
    default:
      return '';
  }
};

const PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

const useDiscount = ({ communityId }) => {
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NUMBER);
  const [discountList, setDiscountList] = useState([]);
  const [pageMetadata, setPageMetadata] = useState({
    pageNo: DEFAULT_PAGE_NUMBER,
    limit: PAGE_SIZE
  });
  const [allEntities, setAllEntities] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPromotionStatus, setSelectedPromotionStatus] =
    useState(null);

  const [isSingleDiscountLoading, setIsSingleDiscountLoading] =
    useState(false);
  const [isDiscountListLoading, setIsDiscountListLoading] =
    useState(false);
  const [isDiscountCreationLoading, setIsDiscountCreationLoading] =
    useState(false);
  const [isDiscountEditLoading, setIsDiscountEditLoading] =
    useState(false);
  const [isDiscountCheckLoading, setIsDiscountCheckLoading] =
    useState(false);

  const getDiscountList = useCallback(async () => {
    try {
      setIsDiscountListLoading(true);

      const params = {
        pageNo: pageNo,
        pageSize: PAGE_SIZE
      };

      if (selectedPromotionStatus) {
        params.status = selectedPromotionStatus;
      }

      if (searchTerm) {
        params.search = searchTerm;
      }

      const { data, error } = await getDiscounts({ params, communityId });

      if (error) throw new Error(error.error);

      setPageMetadata(data?.data?.metadata);
      setDiscountList(data?.data?.discounts ?? []);
      setAllEntities(data?.data?.entities ?? []);
    } catch (e) {
      showErrorToast(e.message);
    } finally {
      setIsDiscountListLoading(false);
    }
  }, [communityId, pageNo, selectedPromotionStatus, searchTerm]);

  useEffect(() => {
    getDiscountList();
  }, [getDiscountList]);

  const getNextDiscountList = () => {
    setPageNo((prev) => prev + 1);
  };

  const getPreviousDiscountList = () => {
    setPageNo((prev) => prev - 1);
  };

  const createSingleDiscountCode = async ({ params }) => {
    try {
      setIsDiscountCreationLoading(true);

      const { data, error } = await createDiscountCode({
        communityId,
        params
      });

      if (error) throw new Error(error.error);

      return { error: null, data };
    } catch (e) {
      return { error: e.message };
    } finally {
      setIsDiscountCreationLoading(false);
    }
  };

  const getSingleDiscountInfo = async ({ communityId, code }) => {
    try {
      setIsSingleDiscountLoading(true);

      const { data, error } = await getDiscountInfo({
        communityId,
        code
      });

      if (error) throw new Error(error.error);

      return { error: null, data };
    } catch (e) {
      return { error: e.message };
    } finally {
      setIsSingleDiscountLoading(false);
    }
  };

  const editSingleDiscountCode = async ({
    params,
    discountId,
    onSuccess
  }) => {
    try {
      setIsDiscountEditLoading(true);

      const { data, error } = await editDiscountCode({
        communityId,
        discountId,
        params
      });

      if (error) throw new Error(error.error);

      onSuccess?.(data?.data);
    } catch (e) {
      showErrorToast(e.message);
    } finally {
      setIsDiscountEditLoading(false);
    }
  };

  const checkIfDiscountCodeExists = async ({ params }) => {
    setIsDiscountCheckLoading(true);

    const { data, error } = await checkDiscountCode({
      communityId,
      params
    });

    setIsDiscountCheckLoading(false);

    return { data, error };
  };

  const updateDiscountList = (selectedDiscount) => {
    const tempDiscountList = [...discountList];

    const updatedDiscountList = tempDiscountList.map((discount) => {
      if (discount._id === selectedDiscount._id) return selectedDiscount;

      return discount;
    });

    setDiscountList(updatedDiscountList);
  };

  const updateSelectedPromotionStatus = (status) => {
    setSelectedPromotionStatus(status);
    setPageNo(DEFAULT_PAGE_NUMBER);
  };

  const updateSearchTerm = (term) => {
    setSearchTerm(term);
    setPageNo(DEFAULT_PAGE_NUMBER);
  };

  return {
    isDiscountCreationLoading,
    isDiscountCheckLoading,
    createSingleDiscountCode,
    checkIfDiscountCodeExists,
    editSingleDiscountCode,
    isDiscountEditLoading,
    discountList,
    isDiscountListLoading,
    getSingleDiscountInfo,
    isSingleDiscountLoading,
    getDiscountList,
    getNextDiscountList,
    getPreviousDiscountList,
    updateDiscountList,
    promotionStatusOptions: getPromotionStatusFilterOptions(
      updateSelectedPromotionStatus
    ),
    selectedPromotionStatus,
    pageMetadata,
    updateSearchTerm,
    searchTerm,
    allEntities
  };
};

export default useDiscount;
